import React from 'react';
import cssVars from 'css-vars-ponyfill';
import 'whatwg-fetch';
import 'polyfills/nodeRemove';
import 'intersection-observer';
import { anchorate } from 'anchorate';
import Layout from 'components/layout';
import { UserProvider } from './src/contexts/userContext';
import { NavigationLinksProvider } from './src/contexts/navigationLinksContext';
import './src/styles/normalize.scss';
import './src/styles/variables.scss';
import './src/styles/base.scss';

/*
 *   Css vars ponyfill
 */
cssVars();

/*
 *   Add class to body when keyboard nav is being used
 *   Used to conditionally add focus highlights and other keyboard-friendly indicators
 */
document.addEventListener('keydown', (evt) => {
  if (evt.key === 'Tab') {
    document.body.classList.add('keyboard-nav');
  }
});

document.addEventListener('click', (e) => {
  if (e.clientX === 0 && e.clientY === 0) return;
  document.body.classList.remove('keyboard-nav');
});

const onRouteUpdate = () => {
  setTimeout(() => {
    anchorate({
      scroller: function (element) {
        if (!element) return false;
        window.scroll(0, element.offsetTop - 85);
        return true;
      },
    });
  }, 100);
};

export { onRouteUpdate };

export const wrapPageElement = ({ element, props }) => {
  return (
    <UserProvider>
      <NavigationLinksProvider>
        <Layout {...props}>{element}</Layout>
      </NavigationLinksProvider>
    </UserProvider>
  );
};
