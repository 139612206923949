import React, { createContext, useReducer, useContext } from 'react';

export const UserContext = createContext();

// Initial state
const initialState = {
  isAuthenticated: false,
  isLoaded: false,
};

// Actions
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE';

// Action creators
export function userFetchSuccess(userData) {
  return { type: USER_FETCH_SUCCESS, userData };
}
export function userFetchFailure(error) {
  return { type: USER_FETCH_FAILURE, error };
}

// Reducer
export function userReducer(state, action) {
  switch (action.type) {
    case USER_FETCH_SUCCESS:
      return { isLoaded: true, ...action.userData };
    case USER_FETCH_FAILURE:
      return { isAuthenticated: false, isLoaded: true, error: action.error };
    default:
      return state;
  }
}

function UserProvider(props) {
  const [user, dispatch] = useReducer(userReducer, initialState);
  const userData = { user, dispatch };
  return <UserContext.Provider value={userData} {...props} />;
}

function useUserContext() {
  return useContext(UserContext);
}

export { UserProvider, useUserContext };
