
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import orderBy from 'lodash/fp/orderBy';
import moment from 'moment';

export default ({ user, product, onlyActive = true }) =>
  flow(
    filter(
      lic =>
        (lic.product === product || !product) && (!onlyActive || lic.is_active),
    ),
    // This logic mirrors the logic in the Django License model.
    orderBy(
      ['is_active', 'is_trial', lic => moment(lic.date_ends)],
      ['desc', 'asc', 'desc'],
    ),
  )(user.licenses);
