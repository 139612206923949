import React, { useState, useEffect, useRef, cloneElement } from 'react';
import classnames from 'classnames';
import Mousetrap from 'mousetrap';
import SmartLink from 'components/smartLink';
import { ReactComponent as CaretIcon } from 'images/icon-caret-down-alt.svg';
import styles from './mainNav.module.scss';

/*
 *   Subnav link
 */
function SubNavItem({ el, hasFocus }) {
  const ref = useRef(null);

  useEffect(() => {
    if (hasFocus) ref.current.focus();
  }, [hasFocus]);

  return cloneElement(el, { linkRef: ref });
}

/*
 *   Nav Item
 */
export function NavItem({ to, label, accordion, children, forceClose }) {
  const [isOpen, setIsOpen] = useState(false);
  const caretRef = useRef(null);
  const isOpenRef = useRef(false);
  const currentFocusRef = useRef(-1);
  const [currentFocus, setCurrentFocus] = useState(-1);

  function open(evt) {
    if (evt) evt.preventDefault();
    setIsOpen(true);
    currentFocusRef.current++;
    setCurrentFocus(currentFocusRef.current);
    Mousetrap.bind(['tab', 'shift+tab'], close);
    Mousetrap.bind('up', handleUpArrow);
  }

  function close() {
    currentFocusRef.current = -1;
    setCurrentFocus(currentFocusRef.current);
    setIsOpen(false);
    if (caretRef.current) caretRef.current.focus();
    Mousetrap.unbind(['tab', 'shift+tab', 'up']);
  }

  function toggle() {
    if (accordion) {
      setIsOpen(!isOpen);
    } else {
      if (!isOpenRef.current) {
        open();
      } else {
        close();
      }
    }
  }

  function handleDownArrow(e) {
    e.preventDefault();

    if (!isOpenRef.current) {
      open();
    } else if (currentFocusRef.current + 1 < children.length) {
      currentFocusRef.current++;
      setCurrentFocus(currentFocusRef.current);
    }
  }

  function handleUpArrow(e) {
    e.preventDefault();

    if (currentFocusRef.current < 1) {
      close();
    } else {
      currentFocusRef.current--;
      setCurrentFocus(currentFocusRef.current);
      // sublinkRefs[--currentFocusRef.current].current.focus();
    }
  }

  function handleCaretFocus() {
    Mousetrap.bind(['enter', 'space'], open);
    Mousetrap.bind('down', handleDownArrow);
  }

  function handleCaretBlur() {
    Mousetrap.unbind(['enter', 'space']);

    if (!isOpenRef.current) Mousetrap.unbind(['down']);
  }

  /*
   *   Use Effect
   */
  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    return () => {
      Mousetrap.unbind(['tab', 'shift+tab', 'down', 'up', 'enter', 'space']);
    };
  }, []);

  useEffect(close, [forceClose]);

  if (children) {
    const refedChildren = children.map((child, i) => <SubNavItem key={i} hasFocus={currentFocus === i} el={child} />);

    const linkEl = accordion ? (
      <div className={styles.menu__link}>{label}</div>
    ) : (
      <SmartLink role='menuitem' to={to} className={styles.menu__link}>
        {label}
      </SmartLink>
    );

    return (
      <div className={classnames(styles.menu__parent, { [styles.is_open]: isOpen, [styles.accordion]: accordion })}>
        <div className={styles.menu__item} onClick={toggle}>
          {linkEl}
          <button
            ref={caretRef}
            className={styles.menu__caret}
            onFocus={handleCaretFocus}
            onBlur={handleCaretBlur}
            aria-label={`Open ${label} dropdown`}
            onClick={toggle}
          >
            <CaretIcon title='Open dropdown' aria-haspopup='true' aria-expanded={isOpen} />
          </button>
        </div>
        <div className={styles.dropdown} role='menu' aria-hidden={!isOpen}>
          {refedChildren}
        </div>
      </div>
    );
  } else {
    return (
      <SmartLink to={to} className={classnames(styles.menu__link, { [styles.accordion]: accordion })}>
        {label}
      </SmartLink>
    );
  }
}

/*
 *   Dropdown item
 */
export function NavDropdownItem({ to, children, linkRef, onClick }) {
  return (
    <SmartLink role='menuitem' ref={linkRef} tabIndex={-1} to={to} className={styles.dropdown__item} onClick={onClick}>
      {children}
    </SmartLink>
  );
}
