import React, { useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import {
  useUserContext,
  userFetchFailure,
  userFetchSuccess,
} from '../contexts/userContext';

const Layout = (props) => {
  const { dispatch } = useUserContext(); // FIXME: Error handling.
  useEffect(() => {
    fetch(`${process.env.MONOLITH_BASE_URL}/api/v2/user/me/`, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((userData) => {
        dispatch(userFetchSuccess(userData));
      })
      .catch((error) => {
        dispatch(userFetchFailure(error));
      });
  }, [dispatch]);

  const { children, location } = props;

  return (
    <main
      css={{
        paddingTop: 'var(--header-height)',
        width: '100%',
      }}
    >
      <Header location={location} />
      <div
        css={{
          maxWidth: '1600px',
          margin: 'auto',
        }}
      >
        {children}
      </div>
      <Footer />
    </main>
  );
};

export default Layout;
