import React, { useEffect, useState } from "react";
import SmartLink from "components/smartLink";
import { useUserContext } from "contexts/userContext";
import { ReactComponent as Logo } from "images/logo.svg";
import { ReactComponent as TwitterIcon } from "images/icon-twitter.svg";
import { ReactComponent as FacebookIcon } from "images/icon-facebook.svg";
import { ReactComponent as InstagramIcon } from "images/icon-instagram.svg";
import { ReactComponent as YoutubeIcon } from "images/icon-youtube.svg";
import { ReactComponent as LinkedinIcon } from "images/icon-linkedin-alt.svg";
import getActiveSubscriptionLicenses from "utils/getActiveSubscriptionLicenses";
import get from "lodash/get";

import styles from "./footer.module.scss";

const serviceForceCode = `
  var initESW = function(gslbBaseURL) {
    embedded_svc.settings.displayHelpButton = true; //Or false
    embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

    embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    embedded_svc.settings.entryFeature = 'LiveAgent';

    embedded_svc.init(
			'https://newsela.my.salesforce.com',
			'https://newsela.my.site.com/educators',
			gslbBaseURL,
			'00D37000000PQ0J',
			'Chat_Agents',
			{
				baseLiveAgentContentURL: 'https://c.la1-core1.sfdc-lywfpd.salesforceliveagent.com/content',
				deploymentId: '5721S000000blL9',
				buttonId: '5731S000000blLE',
				baseLiveAgentURL: 'https://d.la1-core1.sfdc-lywfpd.salesforceliveagent.com/chat',
				eswLiveAgentDevName: 'Chat_Agents',
				isOfflineSupportEnabled: false
			}
		);
  };

	if (!window.embedded_svc) {
		var s = document.createElement('script');
		s.setAttribute('src', 'https://newsela.my.salesforce.com/embeddedservice/5.0/esw.min.js');
		s.onload = function() {
			initESW(null);
		};
		document.body.appendChild(s);
	} else {
		initESW('https://service.force.com');
	}
`;

export default function Footer() {
  const user = useUserContext().user;
  const [
    serviceDeskRemoteScriptLoaded,
    setServiceDeskRemoteScriptLoaded,
  ] = useState(false);
  useEffect(() => {
    const activeSubscriptionLicenses = getActiveSubscriptionLicenses(user);
    const teacherRole = get(user.teacher, "role");
    if (
      activeSubscriptionLicenses.length > 0 &&
      !user.student &&
      teacherRole !== "parent"
    ) {
      const serviceDeskScriptTag = document.createElement("script");
      serviceDeskScriptTag.src =
        "https://service.force.com/embeddedservice/5.0/esw.min.js";
      serviceDeskScriptTag.addEventListener("load", () =>
        setServiceDeskRemoteScriptLoaded(true)
      );
      document.body.appendChild(serviceDeskScriptTag);
    }
  }, [user]);

  useEffect(() => {
    if (!serviceDeskRemoteScriptLoaded) return;
    const serviceDeskChatTag = document.createElement("script");
    serviceDeskChatTag.type = "text/javascript";
    serviceDeskChatTag.appendChild(document.createTextNode(serviceForceCode));
    document.body.appendChild(serviceDeskChatTag);
  }, [serviceDeskRemoteScriptLoaded]);

  return (
    <footer className={styles.container}>
      <div className={styles.inner}>
        <Logo className={styles.logo} role="img" aria-label="Newsela Logo" />
        <div className={styles.footer_footer}>
          <div>&copy; {new Date().getFullYear()} Newsela Inc.</div>
          <div className={styles.legal}>
            <SmartLink
              to="https://newsela.com/about/terms"
              className={styles.footer_link}
            >
              Terms
            </SmartLink>
            <SmartLink
              to="https://newsela.com/about/privacy-policy"
              className={styles.footer_link}
            >
              Privacy
            </SmartLink>
            <SmartLink
              to="https://support.newsela.com/article/newsela-accessibility-statement/"
              className={styles.footer_link}
            >
              Accessibility
            </SmartLink>
          </div>
          <div className={styles.social}>
            <SmartLink
              to="https://twitter.com/Newsela"
              target="_blank"
              className={styles.footer_link}
              alt="Twitter"
              aria-label="Twitter"
            >
              <TwitterIcon />
            </SmartLink>
            <SmartLink
              to="https://www.facebook.com/Newsela/"
              target="_blank"
              className={styles.footer_link}
              alt="Facebook"
              aria-label="Facebook"
            >
              <FacebookIcon />
            </SmartLink>
            <SmartLink
              to="https://www.instagram.com/newsela"
              target="_blank"
              className={styles.footer_link}
              alt="Instagram"
              aria-label="Instagram"
            >
              <InstagramIcon />
            </SmartLink>
            <SmartLink
              to="https://www.youtube.com/user/Newsela/videos"
              target="_blank"
              className={styles.footer_link}
              aria-label="YouTube"
            >
              <YoutubeIcon />
            </SmartLink>
            <SmartLink
              to="https://www.linkedin.com/company/newsela"
              target="_blank"
              className={styles.footer_link}
              alt="LinkedIn"
              aria-label="Linkedin"
            >
              <LinkedinIcon />
            </SmartLink>
          </div>
        </div>
      </div>
    </footer>
  );
}
