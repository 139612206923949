import React, { createContext, useContext, useState, useEffect } from 'react';

const NavigationLinksContext = createContext();

export const NavigationLinksProvider = ({ children }) => {
  const [navigationLinks, setNavigationLinks] = useState(() => {
    const storedNavigationLinks =
      localStorage?.getItem('navigationLinks') || null;
    return storedNavigationLinks ? JSON.parse(storedNavigationLinks) : [];
  });

  useEffect(() => {
    localStorage.setItem('navigationLinks', JSON.stringify(navigationLinks));
  }, [navigationLinks]);

  const addNavigationLink = ({ name, slug, type }) => {
    const linkPosition = navigationLinks.findIndex(
      ({ name: navigationName }) => navigationName === name
    );
    if (linkPosition !== -1) {
      const newNavigationLinks = navigationLinks.slice(0, linkPosition + 1);
      setNavigationLinks(newNavigationLinks);
    } else {
      setNavigationLinks((prevNavigationLinks) => [
        ...prevNavigationLinks,
        { name, slug, type },
      ]);
    }
  };

  const handleNavigationClick = (index) => {
    setNavigationLinks((prevNavigationLinks) =>
      prevNavigationLinks.slice(0, index + 1)
    );
  };

  const resetNavigationLinks = () => {
    localStorage.setItem('navigationLinks', JSON.stringify([]));
    setNavigationLinks([]);
  };

  const findNavigationLink = ({ name, slug, type }) => {
    return navigationLinks?.find(
      (navigationLink) =>
        navigationLink.type === type &&
        navigationLink.name === name &&
        navigationLink.slug.includes(slug)
    );
  };

  const valuesToPass = {
    navigationLinks,
    addNavigationLink,
    handleNavigationClick,
    resetNavigationLinks,
    findNavigationLink,
  };

  return (
    <NavigationLinksContext.Provider value={valuesToPass}>
      {children}
    </NavigationLinksContext.Provider>
  );
};

export const useNavigationLinksContext = () =>
  useContext(NavigationLinksContext);
